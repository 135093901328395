<template>
    <section class="viviendas-residentes">
        <cargando v-if="cargando" />
        <titulo2 titulo="Residentes" @inputSearch="buscador">
            <div class="row justify-content-lg-end">
                <div class="col-auto mx-2">
                    <div class="row bg-f5 border br-8 indicadores">
                        <div class="col-auto my-auto">
                            <p class="f-17 f-500">
                                {{ estadisticas.total_usuarios }}
                                <span class="f-100 pl-2 f-13 text-muted">Total</span>
                            </p>
                        </div>
                        <div class="col pr-0">
                            <div class="indicador1 d-middle-center">
                                <i class="f-20 icon-account-outline op-05" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto mx-2">
                    <div class="row bg-f5 border br-8 indicadores">
                        <div class="col-auto my-auto">
                            <p class="f-17 f-500">
                                {{ estadisticas.total_activos }}
                                <span class="f-100 pl-2 f-13 text-muted">Uso app últ. mes</span>
                            </p>
                        </div>
                        <div class="col pr-0">
                            <div class="indicador3 d-middle-center">
                                <i class="f-18 icon-check-phone op-05" />
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn-general btn btn-sm f-14" @click="crearNuevoResidente">
                    Nuevo residente
                </button>
            </div>
        </titulo2>
        <!-- tabla -->
        <div class="row overflow-auto custom-scroll" style="height:calc(100vh - 180px)">
            <el-table :data="residentes" height="calc(100vh - 215px)" style="width: 100%" @row-click="verResidente">
                <el-table-column prop="tipo" width="60">
                    <template slot-scope="scope">
                        <div class="like-img bg1 br-50 d-middle-center f-20 mx-auto">
                            <i class="icon-account-outline op-05" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column sortable prop="agrupacion" label="Grupo de viviendas" />
                <el-table-column sortable :sort-by="ordenar" label="Vivienda">
                    <template slot-scope="scope">
                        <p>
                            {{ scope.row.vivienda }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column sortable :sort-by="ordenar" label="Residente">
                    <template slot-scope="scope">
                        <p>
                            {{ scope.row.nombre }} {{ scope.row.apellido }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column sortable prop="genero" label="Genero" width="130">
                    <template slot-scope="scope">
                        <span v-if="scope.row.genero == 1">Otro</span>
                        <span v-if="scope.row.genero == 2">Hombre</span>
                        <span v-if="scope.row.genero == 3">Mujer</span>
                    </template>
                </el-table-column>
                <el-table-column sortable prop="telefono" label="Teléfono" width="130" />
                <el-table-column prop="tipo" width="50">
                    <template slot-scope="scope">
                        <!-- Solo sale en cierto tipo -->
                        <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                            <div slot="content" class="tooltip-editar-residente">
                                <div class="d-flex mb-2 editar-opcion py-1 px-1 cr-pointer br-4" @click="editarResidente(scope.row.id)">
                                    <i class="icon-pencil-outline mr-2 f-17" />
                                    <p class="f-15">Editar datos</p>
                                </div>
                                <div class="d-flex my-auto editar-opcion py-1 px-1 cr-pointer br-4" @click="modalEliminarResidente(scope.row.id)">
                                    <i class="icon-delete-outline my-auto mr-2 f-17" />
                                    <div>
                                        <p class="f-15">Eliminar a {{ scope.row.nombre }}</p>
                                        <p class="f-13 text-86">Todo registro será borrado del sistema</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-auto">
                                <i class="icon-vertical-points" />
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <div slot="empty" class="row">
                    <div class="col-12 text-center">
                        <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                        <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No hemos encontrado coincidencias con tu búsqueda</p>
                    </div>
                </div>
                <infinite-loading
                slot="append"
                force-use-infinite-wrapper=".el-table__body-wrapper"
                @infinite="listar"
                />
            </el-table>
        </div>
        <!-- partials -->
        <modal-eliminar ref="abrirEliminarResidente" titulo="Eliminar residente" mensaje="¿Desea eliminar este residente?" adicional="Rechazar" tamano="modal-l" @eliminar="eliminarResidente">
            No podrá acceder a Mi Conjunto y todo registro será borrado del sistema
        </modal-eliminar>
    </section>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';

import Usuarios from '~/services/usuarios'
import Viviendas from '~/services/viviendas'
export default {
    components: {
        InfiniteLoading
    },
    data(){
        return{
            residentes : [],
            estadisticas: {
                total_usuarios : 0,
                total_activos : 0
            },
            page: 1,
            search: null,
            cargando: false,
            idResidente: null
        }
    },
    created(){
        this.residenteEstadisticas()
    },
    methods:{
        crearNuevoResidente(){
            this.$router.push({name:'viviendas.guardar.residente'})
        },
        editarResidente(id){
            this.$router.push({name:'viviendas.guardar.residente.id', params:{id_residente:id}})
        },
        modalEliminarResidente(idResidente){
            this.idResidente = idResidente;
            this.$refs.abrirEliminarResidente.toggle()
        },
        verResidente(row){
            this.$router.push({ name: 'viviendas.ver.residente.personal', params:{id_residente: row.id} })
        },
        async listar($state){
            try {
                const state = $state
                this.cargando = true
                const params={
                    search: this.search,
                    page: this.page
                }
                const {data} = await Usuarios.residentes(params)
                if(data.data.data.length){
                    this.page += 1;
                    this.residentes.push(...data.data.data)
                    if(typeof state !== 'undefined'){
                        state.loaded();
                    }
                }else{
                    if(typeof state !== 'undefined'){
                        state.complete();
                    }
                }
            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false
            }
        },
        async residenteEstadisticas(){
            try {            
                const {data} = await Viviendas.residenteEstadisticas()
                this.estadisticas = data.data
            } catch (e){
                this.errorCatch(e)
            } 
        },
        async buscador(search){
            const busqueda = async() => {
                this.search = search;
                this.residentes = []
                this.page = 1
                this.listar()
            }
            await this.delay(busqueda)
        },
        ordenar(row,key){
            return Number(row.num_vivienda);
        },
        async eliminarResidente(){
            try {
                await Usuarios.eliminarResidente(this.idResidente)
                this.notificacion('','Residente eliminado correctamente','success')
                this.$refs.abrirEliminarResidente.toggle()
                this.residentes = [],
                this.page = 1,
                this.listar()
            } catch (error){
                this.errorCatch(error)
            }
        },
    }
    
}
</script>
<style lang="scss" scoped>

</style>